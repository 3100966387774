<template>
  <div ref="projectWrapper">
    <CSTabBar
      :tabbar="tabBarOptions"
      :checkedTab="checkedTab"
      @changeTabBar="changeTabBar"
    />
    <template v-if="checkedTab === 1">
      <div class="filter-panel">
        <CSSelect>
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择"
            v-model="queryLogParams.startDate"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray" style="margin-left: -10px"> </span>
        <CSSelect>
          <el-date-picker
            type="datetime"
            v-model="queryLogParams.endDate"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择"
          >
          </el-date-picker>
        </CSSelect>
        <input
          type="text"
          class="cs-input"
          v-model="queryLogParams.search"
          placeholder="搜索姓名/昵称/手机号"
        />
        <CSSelect>
          <select v-model="queryLogParams.state">
            <option value="">展示状态不限</option>
            <option value="2">已下架</option>
            <option value="1">展示中</option>
          </select>
        </CSSelect>
        <button class="btn btn-primary" @click="getUpdateLog()">查询</button>
      </div>
      <div class="log-card-wrap">
        <template v-if="updateLogs.length > 0">
          <div class="log-card" v-for="log in updateLogs" :key="log.id">
            <div>
              <img
                :src="log.picture"
                alt=""
                v-if="log.picture"
                class="imgRad"
              />
              <div class="zhanwu" v-else>
                <img src="../../assets/zhanwu.png" class="imgRad" />
                <div>无展示图片</div>
              </div>

              <span class="status-label" :class="{ backg: log.state == 2 }">{{
                log.state == 1 ? "展示中" : "已下架"
              }}</span>
            </div>
            <div class="text-cl">
              <P>
                <span class="name">展示文字</span>
                <span>{{ log.content || "-" }}</span>
              </P>
              <P>
                <span class="name">上传时间</span>
                <span>{{ log.createTime }}</span>
              </P>
              <P>
                <span class="name">姓名</span>
                <span>{{ log.userName || "-" }}</span>
              </P>
              <P>
                <span class="name">昵称</span>
                <span>{{ log.nickName || "-" }}</span>
              </P>
              <P>
                <span class="name">手机号</span>
                <span>{{ log.userPhone || "-" }}</span>
              </P>
              <button
                class="btn btn-primary"
                @click="offTheShelf(log)"
                v-if="log.state == 1"
              >
                下架
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="notDataClass">暂无数据</div>
        </template>
      </div>
      <Pagination :key="2222"></Pagination>
    </template>
    <template v-else>
      <div class="result-panel" style="position: relative">
        <div
          style="height: 20px"
          :style="{ top: scrTopX + 'px' }"
          class="tree-con"
          ref="tree"
        >
          <span style="margin-right: 20px;ma">指定物业员工显示“补充位置”</span>
          <div class="tree-wrap">
            <CSSelect>
              <div
                :class="[
                  'room-select',
                  { unselect: Object.keys(checkedStaffs).length === 0 },
                ]"
                @click.stop="staffTreeVisible = true"
              >
                <template v-if="Object.keys(checkedStaffs).length > 0">
                  已选{{ Object.keys(checkedStaffs).length }}人
                </template>
                <template v-else> 请选择 </template>
              </div>
              <div @click.stop>
                <TreePanel
                  v-show="staffTreeVisible"
                  class="treepanel"
                  style="z-index: 100"
                >
                  <CSTree :tree="staffTreeData" @change="changeStaff" />
                </TreePanel>
              </div>
            </CSSelect>
          </div>
          <button
            class="btn btn-primary"
            @click="setMeetingRoomConfig"
            style="margin-left: 20px;width: 50px;height: 30px;padding: 0"
          >
            保存
          </button>
          <div
            style="
              display: inline-block;
              width: 455px;
              height: 20px;
              top: 8px;
              left: 510px;
            "
            class="tree-con"
          >
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-right: 8px; font-size: 15px; display: inline-block"
            >
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            <span style="color: #999999">
              “补充位置”用于为设备补充地理位置，从而利于判断用户是否在设备附近。
            </span>
          </div>
        </div>
        <CSTable>
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                class="btn btn-primary sticky-right"
                @click="addEquipment"
              >
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                设备以及设置
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>设备SN号</th>
              <th>公用/私用</th>
              <th>设备默认投放内容</th>
              <th>设备摆放地点</th>
              <th>用户上传内容展示规则</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in projectionList" :key="item.id" class="tr-color">
              <td>{{ item.createTime }}</td>
              <td>{{ item.sn }}</td>
              <td>{{ item.type === 1 ? "公用" : "私用" }}</td>
              <td>
                <span
                  class="allow-click"
                  v-if="item.defaultContent"
                  @click="seeContainer(item.defaultContent)"
                  >查看</span
                >
                <template v-else> - </template>
              </td>
              <td>
                <span
                  class="allow-click"
                  v-if="item.longitude"
                  @click="seePlace(item)"
                  >查看</span
                >
                <template v-else> - </template>
              </td>
              <td>
                <span class="allow-click" @click="seeRule(item)">
                  <!--                  v-if="item.type == 1"-->
                  查看</span
                >

                <!--                <template v-else>-</template>-->
              </td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 88px">
                    <li>
                      <a style="width: 100%" @click="updatePro(item)"> 修改 </a>
                    </li>
                    <li>
                      <a style="width: 100%" @click="delProjectBtn(item)">
                        删除
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination></Pagination>
      </div>
    </template>

    <CSDialog
      :dialog-title="
        projectionParams.id ? '修改设备以及设置' : '添加设备以及设置'
      "
      dialog-width="980px"
      dialog-visible
      :is-submitting="isSubmitting"
      :dialogVisible="isShow"
      @onClose="isShow = false"
      @onConfirm="addProjection"
    >
      <template v-slot:dialog-content>
        <div class="dialog-content">
          <div class="dialog-form">
            <div
              class="dialog-form-field"
              style="position: relative"
              :style="{ marginBottom: !projectionParams.id ? '60px' : '30px' }"
            >
              <div class="field-name">设备SN号</div>
              <div>
                <input
                  :disabled="projectionParams.id"
                  style="width: 400px"
                  type="text"
                  placeholder="请输入"
                  v-model="projectionParams.sn"
                />
              </div>
              <div
                class="notes tips"
                v-if="!projectionParams.id"
                style="padding-top: 10px"
              >
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="
                    margin-top: 5px;
                    margin-right: 8px;
                    font-size: 20px;
                    display: inline-block;
                    padding-bottom: 1px;
                  "
                >
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                <p style="display: inline-block">
                  保存后，SN号是不能修改的，请仔细添加
                </p>
              </div>
            </div>

            <div class="dialog-form-field">
              <div class="field-name" style="margin-top: 4px">用途</div>
              <div>
                <CSRadio
                  width="20px"
                  innerWidth="13px"
                  :items="{ 1: '公用', 2: '私用' }"
                  v-model="projectionParams.type"
                />
              </div>
            </div>
            <div class="dialog-form-field">
              <div class="field-name">设备默认投放内容</div>
              <div style="margin-top: 8px">
                <div
                  class="dialog-form-field-item"
                  style="display: inline-block; vertical-align: top"
                >
                  <template
                    v-for="(src, index) in projectionParams.defaultContent"
                  >
                    <div
                      class="commodity-img"
                      :key="index"
                      style="display: inline-block; position: relative"
                    >
                      <img
                        :src="src"
                        style="
                          width: 192px;
                          height: 108px;
                          margin-right: 20px;
                          vertical-align: 0;
                        "
                        v-if="isMedia(src) == 'image'"
                      />
                      <div
                        style="
                          width: 192px;
                          height: 108px;
                          margin-right: 20px;
                          display: inline-block;
                        "
                        v-else
                      >
                        <video
                          :src="src"
                          style="width: 100%; height: 100%"
                          controls="controls"
                          id="upvideo"
                        >
                          您的浏览器不支持视频播放
                        </video>
                      </div>

                      <img
                        @click="deleteFacePhoto(index)"
                        src="@/assets/icon4.png"
                        style="
                          width: 15px;
                          height: 15px;
                          position: absolute;
                          top: 5px;
                          right: 20px;
                        "
                      />
                    </div>
                  </template>
                  <template v-if="projectionParams.defaultContent.length < 5">
                    <div
                      class="upload-photo"
                      style="width: 192px; height: 108px"
                      @click="emitChoosePhoto"
                    >
                      <div class="text-center">
                        <img
                          src="@/assets/相机.png"
                          alt=""
                          style="margin: 5px auto 5px"
                        />
                        <p style="color: #999">1920*1080</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      class="file"
                      id="uploadOwnerPhoto"
                      hidden
                      @change="showImage($event)"
                    />
                  </template>
                </div>
              </div>
            </div>

            <div class="dialog-form-field" v-if="projectionParams.type == 1">
              <div class="field-name">用户上传内容展示规则</div>
              <div>
                <div class="show-rule" style="margin-bottom: 10px">
                  <div class="show-rule-name">展示时间</div>
                  <div>
                    <input
                      style="width: 240px; margin-right: 10px"
                      type="text"
                      placeholder="请输入"
                      v-model="projectionParams.showInterval"
                    />秒
                  </div>
                </div>
                <div class="show-rule">
                  <div class="show-rule-name">播放方式</div>
                  <div>
                    <div style="margin-bottom: 10px">
                      <CSRadio
                        width="20px"
                        :items="{ 1: '轮播', 2: '一次性' }"
                        innerWidth="13px"
                        v-model="projectionParams.showType"
                      />
                    </div>
                    <div v-if="projectionParams.showType == 1">
                      <div class="duilie">
                        循环播放队列
                        <CSSelect height="40px">
                          <select
                            style="width: 120px"
                            v-model="projectionParams.cycleQueue"
                            class="option-cla"
                          >
                            <option
                              v-for="item in ycleQueueList"
                              :key="item"
                              :value="item"
                            >
                              {{ item }}人
                            </option>
                          </select>
                        </CSSelect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialog-form-field" v-else>
              <div class="field-name">展示时间</div>
              <div class="show-rule" style="margin-bottom: 10px">
                <!--                <div class="show-rule-name"></div>-->
                <div>
                  <input
                    style="width: 240px; margin-right: 10px"
                    type="text"
                    placeholder="请输入"
                    v-model="projectionParams.showInterval"
                  />秒
                </div>
              </div>
            </div>
          </div>
          <div class="tips" v-if="projectionParams.showType == 1">
            <svg
              v-if="projectionParams.type == 1"
              class="icon"
              aria-hidden="true"
              style="
                margin-right: 8px;
                font-size: 20px;
                display: inline-block;
                padding-bottom: 1px;
              "
            >
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            <div>
              <p v-if="projectionParams.type == 1">
                例：若展示时间设置为2秒，队列设置10人，则小于等于10人每人展示2秒循环播放；超过10人，最早上传的内容下架。
              </p>
            </div>
          </div>
        </div>
        <div class="layer" v-if="speedProgressVisible">
          <div
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 18px;
              color: #fff;
              transform: translate(-50%, -50%);
              z-index: 999;
            "
          >
            <div class="rotate-out-center"></div>
            <div>已上传{{ speedProgress + "%" }}，请耐心等待...</div>
            <div style="text-align: center">
              <button
                type="button"
                class="btn btn-primary"
                style="margin-top: 20px"
                @click="cancelUpData"
              >
                取消上传
              </button>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- //查看投放内容 -->
    <CSDialog
      dialog-title="设备默认投放内容"
      dialog-width="580px"
      dialog-visible
      :is-submitting="isSubmitting"
      :dialogVisible="isSeeContainer"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
      @onClose="isSeeContainer = false"
      dialog-header-class="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="w-container">
          <div v-for="item in imageVpoList" :key="item" class="w-nei">
            <template v-if="isMedia(item) == 'image'">
              <img
                :src="item"
                style="width: 100%; height: 100%"
                @click="seeImage(item)"
              />
            </template>
            <template v-else>
              <video
                :src="item"
                style="width: 100%; height: 100%"
                controls="controls"
              >
                您的浏览器不支持视频播放
              </video>
            </template>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- //查看设备摆放地址 -->
    <CSDialog
      dialog-title="设备摆放地点"
      dialog-width="580px"
      dialog-visible
      :is-submitting="isSubmitting"
      :dialogVisible="isseePlace"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
      @onClose="isseePlace = false"
      dialog-header-class="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="seeClassPos">{{ seePlacePos }}</div>
      </template>
    </CSDialog>
    <!-- //展示规则 -->
    <CSDialog
      dialog-title="用户上传内容展示规则"
      dialog-width="580px"
      dialog-visible
      :is-submitting="isSubmitting"
      :dialogVisible="isSeeRule"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
      @onClose="isSeeRule = false"
      dialog-header-class="alert-bg"
    >
      <template v-slot:dialog-content>
        <div class="dialog-content">
          <div class="dialog-form">
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">展示时间</div>
              <div>{{ ruleList.showInterval }}秒</div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">播放方式</div>
              <div>
                {{ ruleList.showType == 1 ? "轮播" : "一次性" }}
              </div>
            </div>
            <div class="dialog-form-field" v-if="ruleList.showType == 1">
              <div class="field-name">循环播放队列</div>
              <div>{{ ruleList.cycleQueue }}人</div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import CSRadio from "@/components/common/CSRadio";
import Pagination from "@/components/Pagination";
import { projection } from "@/requestUrl";
import AMapLoader from "@amap/amap-jsapi-loader";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
export default {
  name: "ProjectionManage",
  components: {
    CSRadio,
    CSDialog,
    CSTable,
    CSSelect,
    CSTabBar,
    AMapLoader,
    Pagination,
    TreePanel,
    CSTree,
  },
  data() {
    return {
      scrTopX: 18,
      AMap: null,
      staffList: [],
      canOpenRoomStaffs: {},
      regionId: this.$vc.getCurrentRegion().code,
      staffTreeData: [],
      staffTreeVisible: false,
      checkedStaffs: {},
      timerX: null,
      //查看规则
      isSeeRule: false,
      ruleList: [],
      //查看设备摆放地点
      isseePlace: false,
      seePlacePos: "",
      //查看播放内容
      isSeeContainer: false,
      imageVpoList: [],
      // oss实例
      ossClient: {},
      //上传进度
      speedProgress: 0,
      //文件上传是否成功
      speedProgressVisible: false,
      isShow: false,
      isSubmitting: false,
      tabBarOptions: {
        1: "上传记录",
        2: "设备以及设置",
      },
      checkedTab: 1,
      projectionList: [],
      updateLogs: [],
      queryLogParams: {
        startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD 00:00"),
        endDate: dayjs().format("YYYY-MM-DD 23:59"),
        search: "",
        state: "",
        regionCode: this.$vc.getCurrentRegion().code,
      },
      projectionParams: {
        sn: "",
        type: 1,
        defaultContent: [],
        showInterval: "",
        showType: 1,
        cycleQueue: 10,
      },
      possion: [],
      ycleQueueList: [5, 10, 15, 25, 35, 45, 60],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
    this.loadAMap();
    window.addEventListener("keydown", this.endSelect);
    document.body.addEventListener("click", () => {
      this.staffTreeVisible = false;
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      if (this.checkedTab == 2) {
        this.getProjection(pageNo);
      } else {
        this.getUpdateLog(pageNo);
      }
    });
    // this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
    //   this.getProjection(pageNo);
    // });
    this.$vc.on("this.$route.path", "saveMission", (e) => {
      if (e.pot.val.length > 0) {
        this.possion = e.pot.val[0];
        this.projectionParams.longitude = this.possion[0]; //经度
        this.projectionParams.latitude = this.possion[1]; //纬度
        var x = this.aMapGeocoder(this.possion);
      }
    });

    if (this.checkedTab == 2) {
      this.getProjection();
      this.getStaffList();
    } else {
      this.getUpdateLog();
    }
  },
  activated() {
    //
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.endSelect);
    window.removeEventListener("scroll", this.handleScrollx);
  },
  props: {
    mapKey: {
      type: String,
      default: "6ca9eeedb102d4cb92e7cd64f3258001",
    },
    mapPlugin: {
      type: Array,
      default: () => ["AMap.AutoComplete", "AMap.PlaceSearch"],
    },
  },
  methods: {
    //滚动条滚动距离
    handleScrollx(e) {
      var scrollX = this.$refs.projectWrapper.getBoundingClientRect().top;
      console.log(scrollX);
      if (scrollX < -7) {
        this.scrTopX = -scrollX + 18;
      } else {
        this.scrTopX = 18;
      }
    },
    // 获取员工列表
    async getStaffList() {
      this.staffTreeData = [];
      let canOpenRoomStaffs = {};
      this.checkedStaffs = {};
      const canOpenRoomRes = await this.getOpenRoomStaff();
      canOpenRoomRes.forEach((staff) => {
        this.checkedStaffs[staff.staffUserId] = {
          id: staff.id,
          departmentId: staff.departmentId, //必填，部门编号
          departmentName: staff.departmentName, //必填，部门名称
          staffUserId: staff.staffUserId, //必填，员工编号
          staffName: staff.staffName, //必填，员工姓名
          staffOfficeName: staff.staffOfficeName, //必填，员工职位
          state: staff.state, //设置状态  0-已设置  1-未设置
        };
        staff.state = Number(!staff.state);
        canOpenRoomStaffs[staff.staffUserId] = staff;
      });
      this.canOpenRoomStaffs = canOpenRoomStaffs;
      const departments = await this.$vc.getDepartmentList({});
      departments.map(async (duty) => {
        let children = await this.$vc.getStaffList({
          isOnTheJob: true,
          departmentCode: duty.code,
        });
        children = children.map((child) => {
          child.checked = false;
          if (canOpenRoomStaffs.hasOwnProperty(child.id)) {
            child.openId = canOpenRoomStaffs[child.id].id;
            child.checked = !!canOpenRoomStaffs[child.id].state;
          }
          child.allowCheck = true;
          return child;
        });
        const item = {
          ...duty,
          children,
          checked: false,
          allowCheck: true,
        };
        this.staffTreeData.push(item);
      });
      console.log(this.staffTreeData);
    },
    getOpenRoomStaff() {
      return this.$fly
        .get(projection.getStaffProjectorUrl, {
          staffUserId: "",
          regionId: this.regionId,
        })
        .then((res) => res.data || []);
    },
    changeStaff({ key: index, source }) {
      const item = source[index];
      const checked = item.checked;
      let { checkedStaffs } = this;
      item.checked = !checked;
      if (item.children) {
        item.children.forEach((child) => {
          this.changeCheckedStaff(item.checked, checkedStaffs, child);
          child.checked = item.checked;
        });
      } else {
        this.changeCheckedStaff(item.checked, checkedStaffs, item);
      }
      this.checkedStaffs = checkedStaffs;
    },
    /**
     * 更改选中的员工
     * @param {Boolean} checked 是否选中
     * @param {Object} checkedStaffs 所有选中的员工对象
     * @param {Object} item 当前选择的员工
     * */
    changeCheckedStaff(checked, checkedStaffs, item) {
      let { canOpenRoomStaffs } = this;
      if (checked) {
        const staff = {
          departmentId: item.departmentCode, //必填，部门编号
          departmentName: item.departmentName, //必填，部门名称
          staffUserId: item.id, //必填，员工编号
          staffName: item.name, //必填，员工姓名
          staffOfficeName: item.jobTitle, //必填，员工职位
          state: 0, //设置状态  0-已设置  1-未设置
        };
        if (item.openId) {
          staff.id = item.openId;
        }
        checkedStaffs[item.id] = staff;
      } else {
        if (canOpenRoomStaffs[item.id]) {
          checkedStaffs[item.id].state = 1;
        } else {
          delete checkedStaffs[item.id];
        }
      }
    },
    //下架
    offTheShelf(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定下架吗?`,
        onConfirm: () => {
          this.$fly
            .get(projection.offThShelfPc, {
              id: item.id,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast(`下架成功`);
              this.$CSDialog.instance.closeDialog();
              this.getUpdateLog();
            });
        },
      });
    },
    setMeetingRoomConfig() {
      const { checkedStaffs } = this;
      let params = Object.assign(
        Object.values(checkedStaffs).map((staff) => {
          staff.regionId = this.regionId;
          return staff;
        })
      );
      this.$fly.post(projection.setStaffProjectorUrl, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.$vc.toast("设置成功");
        this.getStaffList();
        console.log(this.staffTreeData);
      });
    },
    //删除设备
    delProjectBtn(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定删除吗?`,
        onConfirm: () => {
          this.$fly
            .get(projection.delProjectionUrl, {
              id: item.id,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast(`删除成功`);
              this.$CSDialog.instance.closeDialog();
              this.getProjection();
            });
        },
      });
    },
    //修改设备
    updatePro(item) {
      this.projectionParams = {
        id: item.id,
        sn: item.sn,
        type: item.type,
        defaultContent: JSON.parse(item.defaultContent),
        showInterval: item.showInterval,
        showType: item.showType,
        cycleQueue: item.cycleQueue,
      };
      this.isShow = true;
    },
    //查看规则
    seeRule(e) {
      this.ruleList = e;
      this.isSeeRule = true;
    },
    //查看设备摆放地点
    seePlace(e) {
      this.aMapGeocoder([e.longitude, e.latitude]);
      this.isseePlace = true;
    },
    //查看图片
    seeImage(e) {},
    //查看设备内容
    seeContainer(e) {
      var arr = JSON.parse(e);
      this.isSeeContainer = true;
      console.log(arr);
      this.imageVpoList = arr;
    },
    //初始化
    init() {
      this.projectionParams = {
        sn: "",
        type: 1,
        defaultContent: [],
        showInterval: "",
        showType: 1,
        cycleQueue: 10,
      };
    },
    //逆向解析地址
    aMapGeocoder(val) {
      var that = this;
      AMap.plugin("AMap.Geocoder", function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "010",
        });
        geocoder.getAddress(val, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            that.seePlacePos = result.regeocode.formattedAddress;
          }
        });
      });
    },
    // 加载地图
    loadAMap() {
      return AMapLoader.load({
        key: this.mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: this.mapPlugin, // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          this.AMap = AMap;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //跳转到地图
    navMap() {
      this.$router.push({
        name: "projectionMap",
      });
    },
    //文件取消上传
    cancelUpData() {
      try {
        this.ossClient.terminationUpload();
      } catch {}
      this.speedProgressVisible = false;
      this.speedProgress = 0;
    },
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
          "uploadImg",
          "upload",
          {
            img: base64,
          },
          {
            headres: {
              "Content-Type": "application/json",
            },
          },
          (resStr, res) => {
            reslove(res.body.fileSaveName);
          },
          (errText, err) => {
            reject(errText);
          }
        );
      });
    },
    //删除图片
    deleteFacePhoto(index) {
      this.projectionParams.defaultContent.splice(index, 1);
    },
    //选择图片
    emitChoosePhoto() {
      $("#uploadOwnerPhoto").trigger("click");
    },
    //判断文件类型
    isMedia(name) {
      let Suffix = /\.(pdf|jpg|png|tif|gif)$/;
      if (Suffix.test(name)) {
        return "image";
      } else {
        return "vdio";
      }
    },
    // 校验文件类型
    verifyFileType(name) {
      let Suffix =
        /\.(mp4|mov|m4v|3gp|avi|m3u8|webm|doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|png|tif|gif)$/;
      return Suffix.test(name);
    },
    showImage(event) {
      console.log("开始上传");
      let files = event.target.files;
      let file = files[0];
      //校验
      if (!this.verifyFileType(file.name)) {
        this.$vc.toast(
          "可选后缀名为mp4,mov,m4v,3gp,avi,m3u8,webm,doc,docx,xls,xlsx,ppt,pptx,pdf,jpg,png,tif,gif 格式的文档"
        );
        console.log("returnfalse了");
        return false;
      }
      //开启进度条
      this.speedProgressVisible = true;
      console.log("调用上传接口");
      //上传
      this.$fly
        .putFile(file, (ossObject) => {
          (this.speedProgress = ossObject.progress),
            (this.ossClient = ossObject.ossClient);
        })
        .then((res) => {
          this.speedProgressVisible = false;
          this.projectionParams.defaultContent.push(res.url);
          console.log("上传成功了");
          //this.uploadInformation();
        });
      event.target.value = "";
    },

    //添加设备
    addEquipment() {
      this.init();
      this.isShow = true;
    },
    changeTabBar(index) {
      this.checkedTab = index;
      if (index == 2) {
        window.removeEventListener("keydown", this.endSelect);
        this.getProjection();
        this.getStaffList();
      } else {
        window.addEventListener("keydown", this.endSelect);
        this.getUpdateLog();
      }
    },
    //回车查询
    endSelect(e) {
      console.log(111);
      if (e.keyCode == 13) {
        this.getUpdateLog();
      }
    },
    addProjection() {
      this.isSubmitting = true;
      if (this.projectionParams.id) {
        this.$fly
          .post(projection.updateProjectorUrl, {
            ...this.projectionParams,
            defaultContent: JSON.stringify(
              this.projectionParams.defaultContent
            ),
          })
          .then((res) => {
            if (res.code !== 0) {
              this.$vc.message(res.msg);
              return;
            }
            this.isShow = false;
            this.getProjection();
            this.$vc.toast("修改成功");
          })
          .finally(() => (this.isSubmitting = false));
      } else {
        this.$fly
          .post(projection.addProjectionUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            ...this.projectionParams,
            defaultContent: JSON.stringify(
              this.projectionParams.defaultContent
            ),
          })
          .then((res) => {
            if (res.code !== 0) {
              this.$vc.message(res.msg);
              return;
            }
            this.isShow = false;
            this.getProjection();
            this.$vc.toast("添加成功");
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
    // 查询上传记录
    getUpdateLog(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(projection.queryUploadLogUrl, {
          ...this.queryLogParams,
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
          this.updateLogs = res.data.datas;
        });
    },
    // 获取投影设备
    getProjection(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(projection.queryProjectionUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          console.log(res);
          if (pageNo === 1) {
            console.log(res);
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
          this.projectionList = res.data.datas;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-content {
  padding: 30px;

  .dialog-form {
    &-field {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 24px;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      .field-name {
        width: 240px;
        margin-right: 40px;
        text-align: right;
        flex: 0 0 240px;
      }

      input {
        border: 1px solid #979797;
        border-radius: 4px;
      }

      .show-rule {
        display: flex;
        flex-flow: row nowrap;

        // align-items center
        &-name {
          width: 120px;
          flex: 0 0 120px;
          margin-right: 40px;
        }
      }
    }
  }

  .tips {
    display: flex;
    font-size: 20px;
    color: #999;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 16px;

    .icon {
      margin-top: 4px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
      line-height: 1.2;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  }
}

.log-card-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0;

  .log-card {
    width: 270px;
    height: 360px;
    background: #FFFFFF;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 15px;
    box-sizing: border-box;

    & > div {
      position: relative;

      img {
        width: 240px;
        height: 135px;
      }

      .status-label {
        position: absolute;
        right: 0;
        width: 60px;
        top: 10px;
        line-height: 24px;
        text-align: center;
        background: #00B694;
        border-radius: 2px 0 0 2px;
        color: #fff;
        font-size: 14px;
      }

      p {
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 14px;

        .name {
          display: inline-block;
          width: 56px;
          text-align: right;
          margin-right: 20px;
        }

        &:last-of-type {
          margin-bottom: 8px;
        }
      }

      .btn {
        float: right;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
}

.dialog-form-field-item {
}

.w-container {
  display: flex;
  justify-content: center;
}

.w-nei {
  width: 150px;
  height: 150px;
  margin: 20px;
  display: inline-block;
}

.seeClassPos {
  font-size: 20px;
  text-align: center;
  margin: 30px 0;
}

.zhanwu {
  width: 240px;
  height: 135px;
  background-color: rgba(240, 240, 240, 0.39);
}

.zhanwu img {
  margin: 30px 85px 2px;
  width: 70px !important;
  height: 50px !important;
}

.zhanwu div {
  color: #999999;
  text-align: center;
  font-size: 14px;
}

.notes {
  font-size: 20px;
  color: #999;
  margin-bottom: 20px;
  display: block !important;
  position: absolute;
  left: 280px;
  top: 22px;
}

.imgRad {
  border-radius: 5px;
}

.backg {
  background-color: #ff5a5a !important;
}

.text-cl {
  color: black;
}

.tr-color {
  color: black;
}

.tree-con {
  position: absolute;
  top: 18px;
  left: 20px;
  z-index: 100;
}

.treepanel {
  position: absolute;
}

.tree-wrap {
  max-width: 250px;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .cs-input {
    width: 215px;
  }
}
.notDataClass{
  margin:0 auto;
  background:#ffff;
  width:100%;
  height:60px;
  text-align: center;
  line-height: 60px;
}
.room-select {
  width: 215px;
  overflow: hidden;
  height: 40px;
  display: inline-flex !important;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 40px;
  padding: 0 6px;
  position: relative;

  &.unselect {
    color: #999;
  }

  span {
    height: 30px;
    line-height: 30px;
    background: #1ab394;
    padding: 0 10px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    border-radius: 15px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.duilie {
  display: inline-block;
  height: 40px;
  margin-right: 4px;
}

.option-cla {
  padding-bottom: 6px;
}
</style>
